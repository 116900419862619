<template>
  <transition :enter-active-class="$style.enter_active" :leave-active-class="$style.leave_active">
    <div v-if="modelValue" :class="$style.modal">
      <div :class="$style.overlay" @click="onClickClose" />
      <div :class="$style.content" :style="{ 'max-width': maxWidth, 'min-height': minHeight }">
        <AtomsConsumerButton v-if="isDisplayCloseButton" :class="$style.close" @click="onClickClose" />
        <div :class="[$style.body, { [$style.visible_button]: isDisplayBottomButton }]">
          <slot />
        </div>
        <div v-if="isDisplayBottomButton" :class="$style.button_area">
          <slot name="button" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref, onUnmounted, watch, computed } from "vue"
import { hexToRgb } from "@tential/ec-gql-schema/utils/color"

type Props = {
  modelValue: boolean
  /** 右上の閉じるボタンを表示するか */
  isDisplayCloseButton?: boolean

  /**
   * モーダル背景色
   * - default: #284b7d($primary)
   * */
  overlayBackgroundColorHex?: string
  maxWidth?: string
  minHeight?: string
  isDisplayBottomButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
  isDisplayCloseButton: true,
  overlayBackgroundColorHex: "#284b7d",
  maxWidth: undefined,
  minHeight: undefined,
  isDisplayButton: false,
})

const scrollY = ref(0)

const emit = defineEmits<{
  (e: "update:modelValue", modelValue: boolean): void
}>()

const overlayBackgroundColorRGBA = computed(() => {
  const { r, g, b } = hexToRgb(props.overlayBackgroundColorHex)
  return `rgba(${r}, ${g}, ${b}, 0.85)`
})

watch(
  () => props.modelValue,
  (value) => {
    if (value) {
      // Modalを開いた時に、スクロール位置を記憶してモーダル裏でスクロールができないようにする
      scrollY.value = window.scrollY
      window.document.body.style.position = "fixed"
      window.document.body.style.top = -1 * scrollY.value + "px"
      window.document.body.style.width = "100%"
      window.history.pushState(null, "", null)
      window.addEventListener("popstate", close)
    } else {
      // Modalを閉じた時に、スクロール位置を元に戻す
      window.document.body.style.position = ""
      if (scrollY.value) window.scrollTo(0, scrollY.value)
      window.removeEventListener("popstate", close)
    }
  },
)

const onClickClose = (): void => {
  history.go(-1)
  close()
}

const close = (): void => {
  emit("update:modelValue", false)
}

onUnmounted(() => {
  window.document.body.style.position = ""
  if (scrollY.value) window.scrollTo(0, scrollY.value)
})
</script>

<style scoped module lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 150;
  display: flex;
  justify-content: center;
  align-items: center;
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: v-bind(overlayBackgroundColorRGBA);
    z-index: 1;
  }
  .content {
    position: relative;
    z-index: 151;
    background-color: $white;
    .close {
      position: absolute;
      top: 32px;
      right: 32px;
      z-index: 152;
      padding: 16px;
      cursor: pointer;
      &::before,
      &::after {
        position: absolute;
        top: 14px;
        left: 0px;
        width: 32px;
        height: 1px;
        content: "";
        background: $primary;
      }
      &::before {
        transform: rotate(45deg);
      }
      &::after {
        transform: rotate(-45deg);
      }
    }
    .body {
      max-width: 100vw;
      max-height: 100vh;
      max-height: 100dvh;
      overflow: auto;
      padding: 1rem;
      &.visible_button {
        padding-bottom: 2.5rem;
      }
      @include md {
        padding: 3rem;
      }
    }
    .button_area {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 1rem;
    }
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.enter_active {
  animation: fade 0.2s;
}
.leave_active {
  animation: fade 0.2s linear reverse;
}
</style>
